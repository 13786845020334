<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="40%"
    :close-on-click-modal="false"
  >
     <div slot="title">
      Детализированная причина неправильной работы
      <el-button class="back_action_panel--first_element custom-button " @click="handleSave" type="text">
         <SaveIcon /> Сохранить
      </el-button>
   </div>
    <el-form :rules="rules" v-loading="loading" ref="misoperationReasonForm" :model="misoperationReason" label-width="12rem">
      <el-form-item prop="name" label="Наименование:">
        <el-input id="misoperation_reason--name_input" v-model="misoperationReason.name"></el-input>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import misoperationReasonsApi from '@/api/nsi/misoperationReasons';
import SaveIcon from '@/assets/icons/save.svg';

export default {
  name: 'MisoperationReasonModal',
  props: ['value', 'misoperationReason'],
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  components: { SaveIcon },
  data() {
    return {
      loading: false,
      rules: {
        name: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
        ]
      },
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    async addMisoperationReason(misoperationReason) {
      await misoperationReasonsApi.addMisoperationReason(misoperationReason);
      this.$emit('on-create');
    },
    async updateMisoperationReason(misoperationReason) {
      await misoperationReasonsApi.updateMisoperationReason(misoperationReason);
      this.$emit('on-update');
    },
    async validateForm() {
      const valid = await this.$refs.misoperationReasonForm.validate();
      return valid;
    },
    async handleSave() {
      const valid = await this.validateForm();
      if (!valid) return;
      // console.log('id', this.misoperationReason.id);

      const request = this.misoperationReason.id ? this.updateMisoperationReason : this.addMisoperationReason;
      this.loading = true;
      await request(this.misoperationReason);
      this.dialogVisible = false;
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">

</style>
