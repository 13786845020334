<template>
  <div>
    <misoperation-reasons-filter @active-change="filterActive = $event" :filter-model="filterModel" />
    <misoperation-reasons-table :filter-active="filterActive" :filter-model="filterModel" />
  </div>
</template>

<script>
import MisoperationReasonsFilter from '@/views/Nsi/MisoperationReasons/MisoperationReasonsFilter';
import MisoperationReasonsTable from '@/views/Nsi/MisoperationReasons/MisoperationReasonsTable';

export default {
  name: 'MisoperationReasons',
  components: { MisoperationReasonsTable, MisoperationReasonsFilter },
  data() {
    return {
      filterModel: {
        nameFilter: '',
        parentNameFilter: ''
      },
      filterActive: true
    };
  }
};
</script>

<style scoped>

</style>
